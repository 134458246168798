import React, { useEffect } from 'react'
// import 'mathlive';
import { ComputeEngine } from '@cortex-js/compute-engine';
import { useSelector } from 'react-redux';

export default function ProblemLatexAnswer({ value, onValueChange }) {
    const locale = useSelector(state => state.culture.locale);
    let currentAnswer = '';
    useEffect(() => {
        currentAnswer = value;
        document.body.style.setProperty("--keyboard-zindex", "1000000000");

        const mf = document.querySelector('#formula');

        function onMathfieldInput() {
            

            try {
                const computeEngine = new ComputeEngine();
                const expression = computeEngine.parse(mf.value);
                const numericValue = expression.N();
                if (typeof numericValue.valueOf() === 'number') {
                    onValueChange(numericValue.valueOf());
                } else {
                    console.log('Latex answer:', mf.value);
                    onValueChange(mf.value);
                }
            } catch (error) {
                console.error('Error evaluating expression:', error);
                onValueChange('invalid parsing of' + mf.value);
            }
        };

        mf.addEventListener('input', onMathfieldInput);

        onMathfieldInput();
    }, []);

    return (
        <math-field
            id="formula"
            style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                borderRadius: '4px',
                border: '1px solid rgba(0, 0, 0, .3)',
                paddingLeft: '5px',
                paddingRight: '5px',
                minWidth: '250px',
                minHeight: '50px'
            }}
            virtual-keyboard-mode="manual"
            locale={locale}
        >
            {currentAnswer}
        </math-field>
    )
}